import {
  BankIDStatus,
  Button,
  FailedHintCode,
  OrderRef,
  PendingHintCode,
  Typography,
  useBankId,
  useDisablePollInBackground,
} from "@lysaab/ui-2";
import { FC, useCallback, useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";
import { InvestmentAccountId } from "../../../data/dataAccounts";
import { useHistory, useLocation, useParams } from "react-router";
import { Location } from "history";
import { Params } from "../UpdatePensionStory";
import { dataLife } from "../../../data/dataLife";
import { parse, stringify } from "query-string";
import {
  signFailedMessages,
  signingMessages,
  signPendingMessages,
} from "./BankIDMessages";
import { TranslatedText } from "../../../components/TranslatedText";
import {
  BeneficiaryAlternative,
  dataLifePension,
  InitUpdateBeneficiaryRequest,
} from "../../../data/dataLifePension";

interface SearchParams {
  orderRef?: string;
  at?: string;
  accountId?: string;
  id?: string;
  t?: string;
}

export interface SignRepaymentUpdateLocationState {
  beneficiary: Exclude<BeneficiaryAlternative, BeneficiaryAlternative.CUSTOM>;
}

interface Props {
  locationState: SignRepaymentUpdateLocationState;
  onSave: () => void;
}

export const SignRepaymentUpdate: FC<Props> = ({ locationState, onSave }) => {
  const intl = useIntl();
  const { accountId } = useParams<Params>();

  const history = useHistory();
  const location = useLocation();
  const urlParams = getParamsFromUrl(location);

  const onComplete = useCallback(
    (_response: any, reset: any) => {
      reset();
      onSave();
    },
    [onSave]
  );

  const onPollError = useCallback((error: any) => {
    console.log(error);
  }, []);

  const pollFnRaw = useMemo(() => {
    const accountId = urlParams.accountId;
    console.log("accountId: ", accountId);
    const orderRef = urlParams.orderRef;
    if (accountId && orderRef) {
      return () =>
        dataLifePension.pollBeneficiarySigning(
          accountId as InvestmentAccountId,
          orderRef
        );
    }
    return undefined;
  }, [urlParams.accountId, urlParams.orderRef]);

  const pollFn = useDisablePollInBackground(pollFnRaw);

  const qrCodePollFn = useCallback(
    () => dataLife.pollQrCode(urlParams.orderRef),
    [urlParams.orderRef]
  );

  const {
    initiate,
    pollStatus,
    latestResponse,
    reset,
    qrCode,
    setOpenOnOtherDevice,
  } = useBankId({
    onComplete,
    onPollError,
    initPollFn: dataLifePension.initBeneficiarySigning,
    pollFn,
    qrCodePollFn,
  });

  const initSigning = useCallback(
    (accountId: InvestmentAccountId, data: InitUpdateBeneficiaryRequest) => {
      if (pollStatus === "IDLE" || pollStatus === "FAILED") {
        initiate(accountId, data).then((response) => {
          console.log("response: ", response);
          if (response) {
            const { orderRef, autoStartToken } = response;
            history.push(
              getUrlWithParams(
                location,
                orderRef,
                accountId,
                autoStartToken,
                locationState
              )
            );
          }
        });
      }
    },
    [pollStatus, initiate, history, location, locationState]
  );

  if (pollStatus === "PENDING" || pollStatus === "FAILED") {
    return (
      <BankIDStatus
        qrCode={qrCode}
        setOpenOnOtherDevice={setOpenOnOtherDevice}
        retry={() => {
          if (urlParams.accountId) {
            initSigning(urlParams.accountId as InvestmentAccountId, {
              alternative: locationState.beneficiary,
            });
          } else {
            reset();
            history.goBack();
          }
        }}
        response={latestResponse}
        getMessages={getMessages(intl)}
        getPendingMessages={getPendingMessages(intl)}
        getFailedMessages={getFailedMessages(intl)}
        autoStartToken={urlParams.autoStartToken}
        reset={() => {
          reset();
          history.goBack();
        }}
      />
    );
  }

  return (
    <>
      <Typography type="h3">
        <TranslatedText id="updatePensionStory.signRepaymentUpdate.bankid.header" />
      </Typography>
      <Typography>
        <TranslatedText id="updatePensionStory.signRepaymentUpdate.bankid.intro" />
      </Typography>
      <Button
        className="cta"
        block
        variant="primary"
        type="button"
        icon="BankId"
        label={intl.formatMessage({
          id: "updatePensionStory.signRepaymentUpdate.bankid.button",
        })}
        onClick={() => {
          console.log("SIGNING WITH BANKID...");
          initSigning(accountId, { alternative: locationState.beneficiary });
        }}
      />
    </>
  );
};

function getMessages(intl: IntlShape) {
  return () => {
    return {
      qrInfo1: intl.formatMessage(signingMessages.qrInfo1),
      qrInfo2: intl.formatMessage(signingMessages.qrInfo2),
      qrInfo3: intl.formatMessage(signingMessages.qrInfo3),
      buttonOpen: intl.formatMessage(signingMessages.buttonOpen),
      buttonErrorHeader: intl.formatMessage(signingMessages.buttonErrorHeader),
      buttonRetry: intl.formatMessage(signingMessages.buttonRetry),
      buttonClose: intl.formatMessage(signingMessages.buttonClose),
      buttonOtherDevice: intl.formatMessage(signingMessages.buttonOtherDevice),
    };
  };
}

function getPendingMessages(intl: IntlShape) {
  return (hintCode: PendingHintCode) =>
    intl.formatMessage(signPendingMessages[hintCode]);
}

function getFailedMessages(intl: IntlShape) {
  return (hintCode: FailedHintCode) =>
    intl.formatMessage(signFailedMessages[hintCode]);
}

function getParamsFromUrl(location: Location) {
  const search = parse(location.search) as SearchParams;
  return {
    orderRef: search.orderRef,
    accountId: search.accountId,
    autoStartToken: search.at,
  };
}

function getUrlWithParams(
  location: Location,
  orderRef: OrderRef | undefined,
  accountId: string,
  autoStartToken: string,
  locationState: SignRepaymentUpdateLocationState
) {
  const search = parse(location.search) as SearchParams;
  search.orderRef = orderRef;
  search.accountId = accountId;
  search.at = autoStartToken;

  return {
    pathname: location.pathname,
    search: stringify(search as Record<string, any>, { skipEmptyString: true }),
    state: locationState,
  };
}
