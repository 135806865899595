import {
  API,
  BankIdInitResponse,
  BankIdStandardResponses,
  cache,
  OrderRef,
} from "@lysaab/ui-2";
import { AccountType, InvestmentAccountId } from "./dataAccounts";

export interface Pension {
  privateProperty: boolean;
  product: AccountType;
  repaymentProtection: boolean;
  withdrawalLength: number;
  withdrawalStartAge: number;
  employer?: string;
}

export enum BeneficiaryType {
  SPOUSE = "SPOUSE",
  CHILD = "CHILD",
  PRIVATE = "PRIVATE",
  NONE = "NONE",
}

export interface Beneficiary {
  priority: number;
  type: BeneficiaryType;
  name?: string;
}

export type StandardBeneficiaryPriority = 1 | 2;
interface StandardBeneficiary extends Beneficiary {
  priority: StandardBeneficiaryPriority;
  type: Extract<
    BeneficiaryType,
    BeneficiaryType.CHILD | BeneficiaryType.SPOUSE
  >;
}

export interface Insured {
  tin: string;
  birthday: string;
  name: string;
}

export enum BeneficiaryAlternative {
  SPOUSE_CHILDREN = "SPOUSE_CHILDREN",
  CHILDREN_SPOUSE = "CHILDREN_SPOUSE",
  CUSTOM = "CUSTOM",
  NONE = "NONE",
}

export interface InitUpdateBeneficiaryRequest {
  // CUSTOM alternative requires manual handling via a (physical) form.
  alternative: Exclude<BeneficiaryAlternative, BeneficiaryAlternative.CUSTOM>;
}

export const dataLifePension = {
  getPensionDetails: (accountId: InvestmentAccountId) => {
    return API.get<Pension>(`/life/pension/${accountId}`);
  },

  getBeneficiaries: (accountId: InvestmentAccountId) => {
    return API.get<Beneficiary[]>(`/life/${accountId}/beneficiaries`).then(
      (response) => [...response].sort((a, b) => a.priority - b.priority)
    );
  },

  getInsured: (accountId: InvestmentAccountId) => {
    return API.get<Insured>(`/life/${accountId}/insured`);
  },

  getBeneficiaryAlternative: (accountId: InvestmentAccountId) => {
    return API.get<BeneficiaryAlternative>(
      `/life/${accountId}/beneficiary-type`
    );
  },

  initBeneficiarySigning: (
    accountId: InvestmentAccountId,
    data: InitUpdateBeneficiaryRequest
  ) => {
    return API.post<BankIdInitResponse>(
      `/life/pension/${accountId}/beneficiaries/init`,
      data
    );
  },

  pollBeneficiarySigning: (
    accountId: InvestmentAccountId,
    orderRef: OrderRef
  ) => {
    return API.get<BankIdStandardResponses>(
      `/life/pension/${accountId}/beneficiaries/sign/${orderRef}`,
      true
    ).then((response) => {
      if (response.status === "complete") {
        cache.delete(`/life/pension/${accountId}`);
        cache.delete(`/life/${accountId}/beneficiaries`);
        cache.delete(`/life/${accountId}/beneficiary-type`);
      }
      return response;
    });
  },
};

/**
 * Standard beneficiaries are SPOUSE and CHILD with priority 1 and 2 in (any order).
 */
export function isStandardBeneficiaries(
  beneficiaries: Beneficiary[]
): beneficiaries is StandardBeneficiary[] {
  return (
    beneficiaries.every(
      ({ type, priority }) =>
        (type === BeneficiaryType.CHILD || type === BeneficiaryType.SPOUSE) &&
        (priority === 1 || priority === 2)
    ) &&
    beneficiaries.length === 2 &&
    beneficiaries[0].type !== beneficiaries[1].type &&
    beneficiaries[0].priority !== beneficiaries[1].priority
  );
}
