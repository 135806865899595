import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Alternative,
  Card,
  Form,
  LysaFormRef,
  Button,
  RadioGroup,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import { Link } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { Consent, dataAutogiro } from "../../../../../../../data/dataAutogiro";
import { TinkAccount } from "../../../../../../../data/dataTink";
import { getNavLink } from "../../../../../../../hooks/useCountryUrls";
import { AutogiroConditions } from "../../../../../components/autogiroConditions/AutogiroConditions";
import { AutogiroDepositContext } from "../AutogiroDepositContext";
import { AUTOGIRO_DEPOSIT_PAGE_URL, BASE_ROUTES } from "../AutogiroDepositPage";
interface Props {
  next: () => void;
}

const messages = defineMessages({
  accountLabel: {
    id: "sweden.deposits.autogiro.story.klarna-account-selection.account.label",
  },
  accountRequired: {
    id: "sweden.deposits.autogiro.story.klarna-account-selection.account.required",
  },
});

const getAccountLabel = (account: TinkAccount) => {
  if (account.name) {
    return `${account.name} - ${account.accountNumber}`;
  }

  return account.accountNumber;
};

export function TinkAccountSelection({ next }: Props) {
  const autogiroContext = useContext(AutogiroDepositContext);
  const [consents, setConsents] = useState<Consent[]>([]);
  const [showConsentConditions, setShowConsentConditions] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const selectedTinkAccount = autogiroContext.state.selectedTinkAccount;
  const formRef = useRef<LysaFormRef>();

  const sortedTinkAccounts = useMemo((): TinkAccount[] => {
    return (
      autogiroContext.state.tinkAccounts?.sort((a, b) => {
        return a.name.localeCompare(b.name);
      }) || []
    );
  }, [autogiroContext.state.tinkAccounts]);

  useEffect(() => {
    if (!autogiroContext.state.tinkAccounts) {
      history.push(getNavLink(AUTOGIRO_DEPOSIT_PAGE_URL));
    }
    dataAutogiro.getConsents().then((consents) => {
      setConsents(consents);
    });
  }, [history, autogiroContext.state.tinkAccounts]);

  useEffect(() => {
    if (
      typeof autogiroContext.state.selectedTinkAccount !== "undefined" &&
      !consents.some(
        (consent) =>
          consent.externalBankAccount ===
          autogiroContext.state.selectedTinkAccount?.accountNumber
      )
    ) {
      setShowConsentConditions(true);
    } else {
      setShowConsentConditions(false);
    }
  }, [consents, autogiroContext.state.selectedTinkAccount]);

  if (!autogiroContext.state.tinkAccounts) {
    return <Spinner />;
  }

  if (autogiroContext.state.tinkAccounts.length === 0)
    return (
      <div>
        <h2>
          <TranslatedText id="sweden.deposits.autogiro.story.klarna-account-selection.no-accounts.header" />
        </h2>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <Typography component="span">
            <TranslatedText id="sweden.deposits.autogiro.story.klarna-account-selection.no-accounts.text" />
          </Typography>
        </Snackbar>
        <Button
          component={Link}
          to={getNavLink(BASE_ROUTES.BANK_SELECTION)}
          block
          label={
            <TranslatedText id="sweden.deposits.autogiro.story.klarna-account-selection.no-accounts.try-again-button" />
          }
        />
      </div>
    );

  return (
    <div>
      <h2>
        <TranslatedText id="sweden.deposits.autogiro.story.klarna-account-selection.header" />
      </h2>
      <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
        <TranslatedText id="sweden.deposits.autogiro.story.klarna-account-selection.information" />
      </Snackbar>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          next();
        }}
      >
        <Card>
          <RadioGroup
            alternatives={sortedTinkAccounts.map(
              (account): Alternative<string> => {
                return {
                  text: getAccountLabel(account),
                  value: account.accountNumber,
                };
              }
            )}
            value={
              selectedTinkAccount
                ? {
                    text: getAccountLabel(selectedTinkAccount),
                    value: selectedTinkAccount.accountNumber,
                  }
                : undefined
            }
            header={intl.formatMessage(messages.accountLabel)}
            onChange={(alt) =>
              autogiroContext.setState({
                selectedTinkAccount: autogiroContext.state.tinkAccounts?.find(
                  (account) => account.accountNumber === alt.value
                ),
              })
            }
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.accountRequired)
              ),
            ]}
          />
          <AutogiroConditions showConsentConditions={showConsentConditions} />
        </Card>
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="sweden.deposits.autogiro.story.klarna-account-selection.button" />
          }
        />
      </Form>
    </div>
  );
}
