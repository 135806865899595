import { FunctionComponent, useCallback, useEffect, useState } from "react";

import { useIntl } from "react-intl";
import { useTransfer } from "../TransferContext";
import { Button, Spinner, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./Sign.scss";
import { Ingress } from "../components/ingress/Ingress";
import { useHistory, useParams } from "react-router";
import {
  dataLifePensionMove,
  PensionMoveSigningOptions,
} from "../../../../../data/dataLifePensionMove";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";
import CheckImage from "../../../../../assets/illustration-check.png";

interface Props {
  next: (caseId: string) => void;
}

type RouteParams = {
  caseId: string;
};

export const Sign: FunctionComponent<Props> = ({ next }) => {
  const intl = useIntl();
  const history = useHistory();
  const { caseId } = useParams<RouteParams>();
  const [transfer, setTransfer] = useTransfer();
  const [state, setState] = useState<"idle" | "loading" | "done" | "error">(
    "idle"
  );

  /**
   * At this point, the transfer has been successfully sent to the backend.
   * This ensures that we can retrieve the transfer data again if the context is lost,
   * such as when the user refreshes the page.
   */
  const fetchTransfer = useCallback(() => {
    setState("loading");
    dataLifePensionMove
      .getMove(caseId)
      .then((res) => {
        const { advice, moves } = res;
        const nextTransfer = {
          withdrawalAge: advice.withdrawalAge,
          withdrawalMonths:
            advice.withdrawalLenght && advice.withdrawalLenght * 12,
          moves: [...moves],
          takenRiskDeviation: advice.takenRiskDeviation,
          caseId: caseId,
          repayment: true,
          sustainability: advice.sustainability,
        };
        setTransfer(nextTransfer);
        setState("done");
      })
      .catch((error) => {
        /**
         * On error, we simply swallow and redirect the user back to the start.
         * This approach is consistent with how we handle context loss in other parts of the story.
         * One could argue for attempting to retrieve the data upon return to avoid creating a new caseId,
         * as the original caseId stored in the backend would otherwise be lost.
         * However, this requires further consideration.
         */
        console.error("Fetching transfer failed", error);
        history.replace(getNavLink(TRANSFER_PENSIONS_URL));
      });
  }, [caseId, setTransfer, history]);

  useEffect(() => {
    // Fetch data only if moves is empty. Which we consider a sign we lost context.
    if (transfer.moves.length > 0) {
      return;
    }
    fetchTransfer();
  }, [fetchTransfer, transfer.moves]);

  if (state === "loading") {
    <Spinner />;
  }

  const hasManualSigning = transfer.moves.some(
    (move) => move.signing === PensionMoveSigningOptions.MANUAL
  );

  return (
    <article className="transfer-pension-sign">
      <header>
        <img src={CheckImage} alt="Leaf" width="192px" height="auto" />
        <Typography type="display-small">
          <TranslatedText id={"sweden.transfer-pension.sign.header"} />
        </Typography>
        <SignIngress hasManualSigning={hasManualSigning} />
      </header>
      <Button
        className="next"
        block
        variant="primary"
        type="button"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.sign.button",
        })}
        onClick={() => {
          next(transfer.caseId);
        }}
      />
    </article>
  );
};

const SignIngress = ({ hasManualSigning }: { hasManualSigning: boolean }) => {
  return hasManualSigning ? (
    <>
      <Ingress>
        <TranslatedText id="sweden.transfer-pension.sign.has-manual-signing.ingress" />
      </Ingress>
    </>
  ) : (
    <>
      <Ingress>
        <TranslatedText id="sweden.transfer-pension.sign.ingress" />
      </Ingress>
    </>
  );
};
