import React, { useEffect, useState } from "react";

import { useIntl } from "react-intl";
import {
  DocumentInformation,
  dataDocuments,
  getLatestDocuments,
} from "../../../../../../data/dataDocuments";
import { DocModal, DocModalLink, InvestmentType } from "@lysaab/ui-2";
import { useUser } from "../../../../../../context/UserContext";

import "./Docs.scss";
import { useTransfer } from "../../TransferContext";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import {
  dataInvestments,
  getPensionAccountQuestions,
  GetPensionSuitabilityAssessmentRequest,
  GetSuitabilityAssessmentResponse,
} from "../../../../../../data/dataInvestments";
import { useLanguage } from "../../../../../../context/LocalizationContext";
import { getUserAge } from "../../utils/userAge";

type InformationType = "ADVANCED_INFORMATION" | "AGREEMENT_AND_TERMS";

const commonDocs = {
  ADVANCED_INFORMATION: [
    ["legal/se/sv/information-om-lysa-v-2023-1.md"],
    ["legal/se/en/LysaLife-infoLysaABdistributor-out.md"],
    [
      "legal/se/sv/information-om-egenskaper-och-risker-med-finansiella-instrument-v-2024-1.md",
    ],
    ["legal/se/sv/bästa-orderutförande-1.md"],
  ],
  AGREEMENT_AND_TERMS: [
    ["legal/se/sv/placeringsriktlinjer-pensionsforsakring.md"],
    ["legal/se/sv/portfoljforvaltningsavtal-pensionsforsakring.md"],
  ],
};

const TJPSpecificDocs = {
  ADVANCED_INFORMATION: [
    ["legal/se/en/LysaLife-forkopsinformation-TP-out.md"],
    ["legal/se/en/LysaLife-faktablad-TP-out.md"],
  ],
  AGREEMENT_AND_TERMS: [["legal/se/en/LysaLife-villkor-TP-out.md"]],
};

const PPFSpecificDocs = {
  ADVANCED_INFORMATION: [
    ["legal/se/en/LysaLife-forkopsinformation-PP-out.md"],
    ["legal/se/en/LysaLife-faktablad-PP-out.md"],
  ],
  AGREEMENT_AND_TERMS: [["legal/se/en/LysaLife-villkor-PP-out.md"]],
};

const getLatestVersionCommon = (
  information: DocumentInformation[],
  informationType: InformationType
) => getLatestDocuments(information, ...commonDocs[informationType]);
const getLatestVersionTJP = (
  information: DocumentInformation[],
  informationType: InformationType
) => getLatestDocuments(information, ...TJPSpecificDocs[informationType]);
const getLatestVersionPPF = (
  information: DocumentInformation[],
  informationType: InformationType
) => getLatestDocuments(information, ...PPFSpecificDocs[informationType]);

interface Props {
  informationType: InformationType;
}

export const Docs: React.FC<Props> = ({ informationType }) => {
  const intl = useIntl();
  const user = useUser();
  const age = getUserAge(user.tin);
  const language = useLanguage();
  const [showDoc, setShowDoc] = useState<string>();
  const [documentList, setDocumentList] = useState<DocumentInformation[]>();
  const [transfer] = useTransfer();
  const [advise, setAdvise] = useState<GetSuitabilityAssessmentResponse>();

  /**
   * We need the advise to populate some of the docs. At this stage in the flow we haven't saved anything in the backend yet.
   * Neither do we have advise in the context. So we go get it.
   */
  useEffect(() => {
    if (typeof transfer?.sustainability !== "undefined") {
      const data: Partial<GetPensionSuitabilityAssessmentRequest> = {
        language: language,
        age: age,
        ...getPensionAccountQuestions(transfer),
      };

      dataInvestments.getNewPensionAccountSuitability(data).then(setAdvise);
    }
  }, [transfer, language, age]);

  useEffect(() => {
    dataDocuments.getDocumentsInformation(true).then(setDocumentList);
  }, []);

  if (!documentList || !advise) {
    return null;
  }

  const hasTJP = transfer?.moves.some((move) => move.type === "LYSA_TJP");
  const hasPPF = transfer?.moves.some((move) => move.type === "LYSA_PPF");

  const latestVersionsCommon = getLatestVersionCommon(
    documentList,
    informationType
  );
  const latestVersionTJP = hasTJP
    ? getLatestVersionTJP(documentList, informationType)
    : [];
  const latestVersionPPF = hasPPF
    ? getLatestVersionPPF(documentList, informationType)
    : [];

  let latestVersions;

  latestVersions = [...latestVersionsCommon];

  if (hasTJP) {
    latestVersions = [...latestVersions, ...latestVersionTJP];
  }

  if (hasPPF) {
    latestVersions = [...latestVersions, ...latestVersionPPF];
  }

  const latestVersionsWithMetaData = latestVersions.map((document) => {
    const match = documentList.find((item) => item.fileName === document);
    return (
      match || {
        fileName: document,
        title: document.replace(".md", ""),
      }
    );
  });

  const actualRisk = Math.min(
    advise.advisedRisk + transfer.takenRiskDeviation,
    100
  );

  const variableValues: Record<string, string> = {
    SIGNING_DATE: intl.formatDate(new Date()),
    CUSTOMER_NAME: user.name ?? "",
    CUSTOMER_ID: user.tin ?? "",
    INVESTMENT_TYPE:
      advise.investmentType === InvestmentType.BROAD
        ? intl.formatMessage({
            id: "sweden.transfer-pension.docs.investmentTypeValueBroad",
          })
        : intl.formatMessage({
            id: "sweden.transfer-pension.docs.investmentTypeValueSustainable",
          }),
    STOCKS: actualRisk
      ? intl.formatNumber(actualRisk / 100, { style: "percent" })
      : "",
    BONDS: actualRisk
      ? intl.formatNumber((100 - actualRisk) / 100, { style: "percent" })
      : "",
  };

  return (
    <section className="transfer-pension-docs">
      <DocModal
        onRequestClose={() => setShowDoc(undefined)}
        active={!!showDoc}
        modalAnnouncement={intl.formatMessage({
          id: "sweden.transfer-pension.docs.modal-announcement",
        })}
        selectPlaceholder={intl.formatMessage({
          id: "sweden.transfer-pension.docs.select-placeholder",
        })}
        docList={latestVersions}
        variableValues={variableValues}
        header={intl.formatMessage({
          id: "sweden.transfer-pension.docs.header",
        })}
        docName={showDoc}
      />
      <div className="link-container">
        {latestVersionsWithMetaData.map((document) => (
          <DocModalLink
            className="link"
            document={document?.fileName}
            modalAnnouncement={document?.title}
            variableValues={variableValues}
            key={document.fileName}
          >
            {document?.title}
          </DocModalLink>
        ))}
        {informationType === "ADVANCED_INFORMATION" && (
          <div className="custom-link-container">
            <a
              className="custom-link"
              href="/docs/LysaLife-flyttbladfribrev-out.pdf"
              download
            >
              <TranslatedText id="sweden.transfer-pension.docs.move-letter" />
            </a>
          </div>
        )}
      </div>
    </section>
  );
};
