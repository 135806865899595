import {
  Alternative,
  Card,
  Checkbox,
  DocModalLink,
  Form,
  LysaFormRef,
  Button,
  RequiredValidator,
  Select,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { TranslatedText } from "../../../components/TranslatedText";
import { LocalizationContext } from "../../../context/LocalizationContext";
import { UserContext } from "../../../context/UserContext";
import { CompoundAccount } from "../../../data/dataAccounts";
import { LegalEntityType } from "../../../data/dataLogin";
import {
  CloseCustomerScenario,
  CloseDownToken,
  dataProfile,
} from "../../../data/dataProfile";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { LOGOUT_PAGE_URL } from "../../logout/LogoutPage";
import { OVERVIEW_PAGE_URL } from "../../overview/OverviewPage";
import { BASE_ROUTES, CloseAccountState } from "../CloseLysaCustomerStory";
import {
  CloseCustomerReason,
  getCloseLysaCustomerReasonsAlternatives,
} from "./closeLysaCustomerReasons/CloseLysaCustomerReasons";
import { ConfirmModal } from "./components/ConfirmModal";
import { ExternalAccounts } from "./components/ExternalAccounts";
import { usePendingDeposits } from "../../../hooks/usePendingDeposits";
import { LysaCountry } from "@lysaab/shared";
import { moneyOnAccount } from "../intro/Intro";
import { useAccounts } from "../../../hooks/useAccounts";
import { dataSavingsAccountInterest } from "../../../data/dataSavingsAccountInterest";
import { Eligibility } from "../../../data/dataInvestments";
import { isValidEligibilityStatePerson } from "../../../context/EligibilityContext";
import { SuitabilityAssessmentReturnState } from "../../suitabilityAssessment/AccessGuardRoute";

const messages = defineMessages({
  understandAlternative: {
    id: "closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.understandAlternative",
  },
  whyQuestionPlaceholder: {
    id: "closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.whyQuestionPlaceholder",
  },
  whyQuestionLabel: {
    id: "closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.whyQuestionLabel",
  },
  understandRequired: {
    id: "closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.understandRequired",
  },
});

interface Props {
  addWithdrawalAccountUrl?: string;
  returnState?: CloseAccountState;
}

const privacyNoteDocuments: Record<LysaCountry, { doc: string }> = {
  [LysaCountry.SWEDEN]: {
    doc: "legal/se/sv/personuppgiftspolicy.md",
  },
  [LysaCountry.DENMARK]: {
    doc: "legal/all/en/privacy-note.md",
  },
  [LysaCountry.FINLAND]: {
    doc: "legal/all/en/privacy-note.md",
  },
  [LysaCountry.GERMANY]: {
    doc: "legal/de/de/privacy-note.md",
  },
  [LysaCountry.SPAIN]: {
    doc: "legal/es/en/privacy-note.md",
  },
};

export const CloseLysaCustomerConfirmation = ({
  addWithdrawalAccountUrl,
  returnState,
}: Props) => {
  const [selectedExternalAccount, setSelectedExternalAccount] =
    useState<Alternative<string>>();
  const [understandCheckboxChecked, setUnderstandCheckboxChecked] =
    useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [whyAnswer, setWhyAnswer] =
    useState<Alternative<CloseCustomerReason>>();
  const [isLoading, setIsLoading] = useState(true);
  const [closedownToken, setClosedownToken] = useState<CloseDownToken>();
  const [error, setError] = useState(false);
  const [hasMeansOnAccounts, setHasMeansOnAccounts] = useState(false);
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const history = useHistory();
  const userContext = useContext(UserContext);
  const localizationContext = useContext(LocalizationContext);
  const [hasPendingDeposit, setHasPendingDeposit] = useState(true);
  const [hasAccruedInterest, setHasAccruedInterest] = useState(true);
  const loadPendingDeposits = usePendingDeposits();
  const { accounts } = useAccounts();
  const [closingEligibility, setClosingEligibility] = useState<Eligibility>();

  const eligibilityState = (
    returnState?.returnState as SuitabilityAssessmentReturnState
  ).eligibilityState;

  useEffect(() => {
    if (userContext.state.legalEntityType === LegalEntityType.CORPORATION) {
      history.push(getNavLink(OVERVIEW_PAGE_URL));
      return;
    }
    if (
      typeof loadPendingDeposits === "undefined" ||
      typeof accounts === "undefined"
    ) {
      return;
    }

    if (isValidEligibilityStatePerson(eligibilityState)) {
      setClosingEligibility({
        legalEntityType: eligibilityState.legalEntityType,
        financial: eligibilityState.financial,
        risk: eligibilityState.risk,
      });
    }

    Promise.all([
      dataProfile.getClosedownToken(),
      loadPendingDeposits(),
      accounts.savingsAccounts.length > 0
        ? dataSavingsAccountInterest.getMultipleAccruedInterest([
            ...accounts.savingsAccounts.map(
              (savingsAccount) => savingsAccount.accountId
            ),
          ])
        : [],
    ])
      .then(([closedownToken, pendingDeposits, accruedInterestResponse]) => {
        const allAccounts = [
          ...accounts.investmentAccounts,
          ...accounts.savingsAccounts,
        ];
        const meansOnAccounts = allAccounts.reduce(
          (accumulator: number, account: CompoundAccount) =>
            accumulator + moneyOnAccount(account),
          0
        );
        const hasAccruedInterest = accruedInterestResponse.some(
          (account) => account.accruedInterest > 0
        );
        setHasAccruedInterest(hasAccruedInterest);
        setHasMeansOnAccounts(meansOnAccounts > 0);
        setClosedownToken(closedownToken.token);
        setHasPendingDeposit(
          allAccounts.some((account) => {
            return pendingDeposits.some(
              (deposit) => deposit.accountId === account.accountId
            );
          })
        );
      })
      .catch(() => setError(true))
      .finally(() => setIsLoading(false));
  }, [
    accounts,
    history,
    loadPendingDeposits,
    userContext.state.legalEntityType,
    eligibilityState,
  ]);

  const postEndCustomer = () => {
    if (
      typeof closedownToken === "undefined" ||
      hasPendingDeposit ||
      ((hasMeansOnAccounts || hasAccruedInterest) &&
        typeof selectedExternalAccount?.value === "undefined")
    ) {
      return;
    }
    setIsLoading(true);
    dataProfile
      .closeCustomer(
        true, // Always get tax information on mail
        closedownToken,
        returnState?.scenario ?? CloseCustomerScenario.CLOSE,
        closingEligibility,
        selectedExternalAccount?.value
      )
      .then(() => {
        history.push(getNavLink(LOGOUT_PAGE_URL));
      })
      .catch(() => setError(true))
      .finally(() => setIsLoading(false));
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Fragment>
      {error && (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <TranslatedText id="closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.error" />
        </Snackbar>
      )}
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid || error) {
            return;
          }
          setShowConfirm(true);
        }}
      >
        {(hasMeansOnAccounts || hasAccruedInterest) && (
          <Fragment>
            <h2>
              <TranslatedText id="closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.withDrawalAccountHeader" />
            </h2>
            <Card>
              <ExternalAccounts
                addWithdrawalAccountUrl={addWithdrawalAccountUrl}
                selectedExternalAccount={selectedExternalAccount}
                setSelectedExternalAccount={setSelectedExternalAccount}
                returnState={returnState}
              />
            </Card>
          </Fragment>
        )}
        <h2>
          <TranslatedText id="closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.yourDataHeader" />
        </h2>
        <Card>
          <p>
            <TranslatedText id="closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.yourDataText" />
          </p>
          <Button
            variant="secondary"
            block
            onClick={() => {
              history.replace(getNavLink(BASE_ROUTES.YOUR_DATA));
            }}
            label={
              <TranslatedText id="closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.myData" />
            }
          />
        </Card>
        <h2>
          <TranslatedText id="closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.ConfirmHeader" />
        </h2>
        <Card>
          <p>
            <TranslatedText
              id="closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.emailInformation"
              values={{
                link: (text) => (
                  <DocModalLink
                    document={
                      privacyNoteDocuments[
                        localizationContext.state.country || LysaCountry.SWEDEN
                      ].doc
                    }
                    modalAnnouncement=""
                  >
                    {text}
                  </DocModalLink>
                ),
              }}
            />
          </p>
          <Select
            placeholder={intl.formatMessage(messages.whyQuestionPlaceholder)}
            alternatives={getCloseLysaCustomerReasonsAlternatives(intl)}
            value={whyAnswer}
            onChange={setWhyAnswer}
            label={intl.formatMessage(messages.whyQuestionLabel)}
          />
          <Checkbox
            alternative={{
              text: intl.formatMessage(messages.understandAlternative),
              value: true,
            }}
            checked={understandCheckboxChecked}
            onChange={() =>
              setUnderstandCheckboxChecked(!understandCheckboxChecked)
            }
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.understandRequired)
              ),
            ]}
          />
        </Card>
        {!error && (
          <Fragment>
            <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
              <TranslatedText id="closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.confirmSnackbarText" />
            </Snackbar>
            <Button
              variant="negative"
              type="submit"
              block
              label={
                <TranslatedText id="closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.confirmButton" />
              }
            />
          </Fragment>
        )}
      </Form>
      <ConfirmModal
        showModal={showConfirm}
        onConfirm={() => {
          postEndCustomer();
          setShowConfirm(false);
        }}
        onDeny={() => {
          setShowConfirm(false);
        }}
      />
    </Fragment>
  );
};
