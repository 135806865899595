import { FC, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import {
  Button,
  Form,
  LysaFormRef,
  LysaLinkButton,
  RadioGroupCard,
  RadioGroupSimpleCard,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Typography,
} from "@lysaab/ui-2";
import { TranslatedText } from "../../../components/TranslatedText";
import { Modal } from "../../../components/modal/Modal";
import { BeneficiaryAlternative } from "../../../data/dataLifePension";

import "./UpdateRepaymentProtection.scss";

// TODO: Update RadioGroupCard to handle empty description.

interface Props {
  repaymentProtection: boolean;
  beneficiary: BeneficiaryAlternative | undefined;
  lifeLong: boolean;
  action: "next" | "save";
  onNext: (
    value: Exclude<BeneficiaryAlternative, BeneficiaryAlternative.CUSTOM>
  ) => void;
}

export const UpdateRepaymentProtection: FC<Props> = ({
  repaymentProtection: currentRepaymentProtection,
  beneficiary: currentBeneficiary,
  lifeLong,
  action,
  onNext,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const formRef = useRef<LysaFormRef>();

  const [showModal, setShowModal] = useState(false);
  const [repaymentProtection, setRepaymentProtection] = useState(
    currentRepaymentProtection
  );
  const [beneficiary, setBeneficiary] = useState(currentBeneficiary);

  const canNotUpdate =
    beneficiary === BeneficiaryAlternative.CUSTOM ||
    !currentRepaymentProtection;

  return (
    <section className="UpdateRepaymentProtection">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (canNotUpdate) {
            history.goBack();
            return;
          }

          if (formRef.current?.isValid && beneficiary) {
            onNext(beneficiary);
          }
        }}
      >
        <RadioGroupSimpleCard<boolean>
          legend={
            <>
              <Typography type="h3">
                <TranslatedText id="storyComponents.updatePension.updateRepaymentProtection.header" />
              </Typography>
              <Typography>
                <TranslatedText
                  id="storyComponents.updatePension.updateRepaymentProtection.body"
                  values={{
                    link: (text) => {
                      return (
                        <LysaLinkButton onClick={() => setShowModal(true)}>
                          {text}
                        </LysaLinkButton>
                      );
                    },
                  }}
                />
              </Typography>
            </>
          }
          alternatives={[
            {
              text: intl.formatMessage({
                id: "storyComponents.updatePension.updateRepaymentProtection.repayment.yes",
              }),
              value: true,
              disabled: lifeLong,
            },
            {
              text: intl.formatMessage({
                id: "storyComponents.updatePension.updateRepaymentProtection.repayment.no",
              }),
              value: false,
            },
          ]}
          value={repaymentProtection}
          onChange={(value) => {
            setRepaymentProtection(value);
            setBeneficiary(value ? undefined : BeneficiaryAlternative.NONE);
          }}
          validators={[
            new RequiredValidator(
              intl.formatMessage({
                id: "storyComponents.updatePension.updateRepaymentProtection.required",
              })
            ),
          ]}
        />

        <SnackbarMessage
          lifeLong={lifeLong}
          repaymentProtection={repaymentProtection}
          currentRepaymentProtection={currentRepaymentProtection}
        />

        {repaymentProtection && (
          <RadioGroupCard<BeneficiaryAlternative>
            legend={
              <Typography type="h3">
                <TranslatedText id="storyComponents.updatePension.updateRepaymentProtection.beneficiary.header" />
              </Typography>
            }
            alternatives={[
              {
                value: BeneficiaryAlternative.SPOUSE_CHILDREN,
                header: intl.formatMessage({
                  id: "storyComponents.updatePension.updateRepaymentProtection.beneficiary.spouse-children.header",
                }),
                description: intl.formatMessage({
                  id: "storyComponents.updatePension.updateRepaymentProtection.beneficiary.spouse-children.description",
                }),
              },
              {
                value: BeneficiaryAlternative.CHILDREN_SPOUSE,
                header: intl.formatMessage({
                  id: "storyComponents.updatePension.updateRepaymentProtection.beneficiary.children-spouse.header",
                }),
                description: intl.formatMessage({
                  id: "storyComponents.updatePension.updateRepaymentProtection.beneficiary.children-spouse.description",
                }),
              },
              {
                value: BeneficiaryAlternative.CUSTOM,
                header: intl.formatMessage({
                  id: "storyComponents.updatePension.updateRepaymentProtection.beneficiary.other.header",
                }),
                description: "",
                expandableContent: (
                  <div className="download-container">
                    <Typography type="body" variant="secondary">
                      <TranslatedText id="storyComponents.updatePension.updateRepaymentProtection.beneficiary.download.body" />
                    </Typography>
                    <Button
                      component="a"
                      type="button"
                      download
                      variant="outlined"
                      icon="Download"
                      target="_blank"
                      block
                      label={intl.formatMessage({
                        id: "storyComponents.updatePension.updateRepaymentProtection.beneficiary.download.button",
                      })}
                      href="/docs/pension-account/Blankett_formanstagare_4.pdf"
                    />
                  </div>
                ),
              },
            ]}
            value={beneficiary}
            onChange={(value) => {
              setBeneficiary(value);
            }}
            validators={[
              new RequiredValidator(
                intl.formatMessage({
                  id: "storyComponents.updatePension.updateRepaymentProtection.required",
                })
              ),
            ]}
          />
        )}

        <Button
          block
          type="submit"
          label={
            canNotUpdate
              ? intl.formatMessage({
                  id: "storyComponents.updatePension.updateRepaymentProtection.button-back",
                })
              : action === "save"
              ? intl.formatMessage({
                  id: "storyComponents.updatePension.updateRepaymentProtection.button-save",
                })
              : intl.formatMessage({
                  id: "storyComponents.updatePension.updateRepaymentProtection.button-next",
                })
          }
        />
      </Form>

      <Modal
        header={intl.formatMessage({
          id: "storyComponents.updatePension.updateRepaymentProtection.modal.header",
        })}
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        closeOnOverlayClick
      >
        <Typography>
          <TranslatedText id="storyComponents.updatePension.updateRepaymentProtection.modal.body" />
        </Typography>
      </Modal>
    </section>
  );
};

interface SnackbarMessageProps {
  lifeLong: boolean;
  repaymentProtection: boolean;
  currentRepaymentProtection: boolean;
}

const SnackbarMessage: FC<SnackbarMessageProps> = ({
  lifeLong,
  repaymentProtection,
  currentRepaymentProtection,
}) => {
  let message;

  if (!currentRepaymentProtection) {
    message = (
      <TranslatedText id="storyComponents.updatePension.updateRepaymentProtection.snackbar.contact-us" />
    );
  } else if (currentRepaymentProtection && !repaymentProtection) {
    message = (
      <TranslatedText id="storyComponents.updatePension.updateRepaymentProtection.snackbar.are-you-sure" />
    );
  } else if (lifeLong) {
    message = (
      <TranslatedText id="storyComponents.updatePension.updateRepaymentProtection.snackbar.life-long" />
    );
  }

  if (message) {
    return (
      <Snackbar type={SNACKBAR_TYPES.INFO} icon textAlign="left">
        {message}
      </Snackbar>
    );
  }

  return null;
};
