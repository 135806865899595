import { useCallback } from "react";
import { EventTracker } from "../../../../../components/eventTracker/EventTracker";

import { TranslatedText } from "../../../../../components/TranslatedText";
import { TrackerEvent } from "../../../../../data/dataCustomerTracking";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { useMonthlyContext } from "../MonthlyContext";
import { BASE_ROUTES } from "../MonthlyStory";
import { BankSelection } from "../../../../../components/tink/BankSelection";
import { TinkAvailableBank } from "../../../../../data/dataTink";

interface Props {
  next: () => void;
}

export function TinkBankSelectionWrapper({ next }: Props) {
  const monthlyContext = useMonthlyContext();
  const setMonthlyContext = monthlyContext.setState;

  const onComplete = useCallback(
    (bank: TinkAvailableBank) => {
      setMonthlyContext({ tinkBank: bank });
      EventTracker.track({
        event: TrackerEvent.TINK_SELECTED_BANK,
        message: bank.name,
      });
      setTimeout(() => {
        next();
      }, 0);
    },
    [next, setMonthlyContext]
  );

  return (
    <BankSelection
      onComplete={onComplete}
      missingBank={{
        pathname: getNavLink(BASE_ROUTES.MANUAL_ACCOUNT_SELECTION),
        state: {
          returnUrl: getNavLink(BASE_ROUTES.EXTERNAL_ACCOUNT),
          returnState: monthlyContext.state,
        },
      }}
      missingBankHeader={
        <TranslatedText id="klarna.bank-selection.missing-bank.header" />
      }
      missingBankDescription={
        <TranslatedText id="klarna.bank-selection.missing-bank.description" />
      }
    />
  );
}
